module.exports={
  "locale": "fr-FR",
  "assets": "depp",
  "showPassword": true,
  "loginMode": "online",
  "maintenance": false,
  "displayAttemptAccounts": false,
  "displayUnavailableAssignments": false,
  "api":{
    "login": "https://depp22-batch1-preprod.depp.taocloud.fr/__r/xnt/api/v1/auth/token",
    "logout": "https://depp22-batch1-preprod.depp.taocloud.fr/__r/xnt/api/v1/auth/logout",
    "refreshToken": "https://depp22-batch1-preprod.depp.taocloud.fr/__r/xnt/api/v1/auth/refresh-token",
    "assignments": "https://depp22-batch1-preprod.depp.taocloud.fr/__r/xnt/api/v1/assignments",
    "lti": "https://depp22-batch1-preprod.depp.taocloud.fr/__r/xnt/api/v1/assignments/{assignmentId}/lti-link"
  },
  "return_url": "/index.html"
}
